import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./plugins/element.js";
import "./assets/css/all.css";
import "./assets/css/bootstrap.min.css";
import "./assets/css/custom.css";
import VueLodash from "vue-lodash";
import Notifications from "vue-notification";
import VueMoment from "vue-moment";
import VuePluralize from "vue-pluralize";
import VueFilterPrettyBytes from "vue-filter-pretty-bytes";
import PrimeVue from "primevue/config";
import {
  BreadcrumbPlugin,
  SpinnerPlugin,
  CollapsePlugin,
  CardPlugin,
  ButtonPlugin,
  PaginationPlugin,
  MediaPlugin,
  AlertPlugin,
  ProgressPlugin,
  ListGroupPlugin,
  BadgePlugin,
} from "bootstrap-vue";
import numeral from "numeral";
import numFormat from "vue-filter-number-format";
import lodash from "lodash";
import moment from "moment-timezone";
import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
Vue.filter("number", numFormat(numeral));

require("moment/locale/es");
moment.locale("es", {
  monthsShort: [
    "Ene",
    "Feb",
    "Mar",
    "Abr",
    "May",
    "Jun",
    "Jul",
    "Ago",
    "Sep",
    "Oct",
    "Nov",
    "Dic",
  ],
  calendar: {
    lastWeek: "L LT",
    sameElse: "L LT",
  },
});

Vue.use(VueMoment, {
  moment,
});
Vue.use(VueFilterPrettyBytes);
Vue.use(VuePluralize);
Vue.use(Notifications);
Vue.use(BreadcrumbPlugin);
Vue.use(SpinnerPlugin);
Vue.use(CollapsePlugin);
Vue.use(CardPlugin);
Vue.use(ButtonPlugin);
Vue.use(PaginationPlugin);
Vue.use(MediaPlugin);
Vue.use(BadgePlugin);
Vue.use(AlertPlugin);
Vue.use(ProgressPlugin);
Vue.use(ListGroupPlugin);
Vue.use(VueLodash, { lodash: lodash });
Vue.use(PrimeVue);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount("#app");
