
import { Component, Vue } from "vue-property-decorator";
import TheNavBar from "@/components/TheNavBar.vue";
import TheSideBar from "@/components/TheSideBar.vue";

@Component({
  components: {
    TheNavBar,
    TheSideBar,
  },
})
/**
 * @desc Home component script.
 * @extends {Vue}
 */
export default class Home extends Vue {
  hideSidebar() {
    const sidebar = document.getElementById("sidebar");
    const main = document.getElementById("main");
    const navbar = document.getElementById("navbar");
    const btnHide = document.getElementById("btn-hide");
    const boxed = document.getElementById("boxed");

    if (sidebar) sidebar.classList.toggle("active");
    if (navbar) navbar.classList.toggle("active");
    if (main) main.classList.toggle("active");
    if (btnHide) btnHide.classList.toggle("active");
    if (boxed) boxed.classList.toggle("active");

    this.$store.commit(
      "updateCollapseSidebar",
      !this.$store.getters.collapseSidebar
    );
  }

  handleScroll() {
    const element = document.getElementById("main");
    const sidebar = document.getElementById("sidebar");
    const boxed = document.getElementById("boxed") || null;
    let activeDiv = false;
    let scrollH = 0;
    if (sidebar) activeDiv = sidebar.classList.contains("active");
    if (element) scrollH = element.scrollTop;

    if (boxed && scrollH) {
      if (scrollH >= 80) {
        boxed.classList.add("show");
      } else {
        boxed.classList.remove("show");
      }
    }
    if (boxed && activeDiv) {
      if (activeDiv == true) {
        boxed.classList.add("active");
      }
    }
  }

  created() {
    if (this.$store.getters.collapseSidebar) this.hideSidebar();
  }
}
