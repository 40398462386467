import { State } from "./state";

const getters = {
  currentTitle: (state: State) => state.currentTitle,
  breadcrumbItems: (state: State) => state.breadcrumbItems,
  loginStatus: (state: State) => state.status,
  authStatus: (state: State) => state.authenticated,
  currentMenuIndex: (state: State) => state.currentMenuIndex,
  collapseSidebar: (state: State) => state.collapseSidebar,
};

export default getters;
