import Vue from "vue";

/**
 * @description A class which makes a little wrapper for vue-notifications into
 * static methods.
 */
export default class Notify {
  static get ERROR(): string {
    return "error";
  }
  static get WARNING(): string {
    return "warn";
  }
  static get SUCCESS(): string {
    return "success";
  }
  static get INFO(): string {
    return "info";
  }

  static show = (text: string, type: string, title?: string) =>
    Vue.notify({ type, text, title });
  static successful = (text: string, title?: string) =>
    Vue.notify({ type: Notify.SUCCESS, text, title });
  static error = (text: string, title?: string) => {
    if (!text) text = "Hubo un problema al conectar con el servicio";
    Vue.notify({ type: Notify.ERROR, text, title });
  };
  static info = (text: string, title?: string) =>
    Vue.notify({ type: Notify.INFO, text, title });
  static warning = (text: string, title?: string) =>
    Vue.notify({ type: Notify.WARNING, text, title });

  /**
   * @description A method which handles notifications when Geb response is
   * between 300 and 521.
   * @param error The error thrown in axios.
   * @returns {Void}
   */
  static gebServerError = (error: any) => {
    console.log(error.response);

    const { message, data } = error.response.data;
    if (
      typeof data.code !== "undefined" &&
      typeof data.description !== "undefined"
    ) {
      const msg: Array<string> = [];
      for (const k in data.description) msg.push(data.description[k]);
      return Notify.error(msg.join("\n"), data.code);
    }
    if (typeof data.errors === "undefined" || !data.errors.length) {
      return Notify.error(message);
    }
    let err: Array<string> = [];
    for (const k in data.errors) err = err.concat(data.errors[k].message);
    Notify.error(err.join("\n"));
  };
}
