import Permission from "@/models/Permission";

export default class User {
  id?: number;
  name!: string;
  lastname!: string;
  email!: string;
  password?: string;
  active = true;
  permissions!: Array<Permission>;
  permissionsIds?: Array<number | undefined>;
  createdAt?: Date;
  updatedAt?: Date;
}
